import { createStore } from 'vuex'

let interval:any = null; // interval 변수를 정의합니다.

export default createStore({
  state: {
    Id:'',
    role: '',
    tokenSize: 0,
    activePetIndex: 0,
    timer: parseInt(String(localStorage.getItem('timer'))) || 0, // 로컬 Save소에서 타이머 값을 가져오고, 없을 경우 0으로 설정합니다.
  },
  
  getters: {
    getId(state){
      return state.Id;
    },
    getRole(state){
      return state.role;
    },
    formattedTimer(state) {
      const hours = Math.floor(state.timer / 3600);
      const minutes = Math.floor((state.timer % 3600) / 60);
      const seconds = state.timer % 60;
      return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
        2,
        "0"
      )}:${String(seconds).padStart(2, "0")}`;
    },
  },  
  mutations: {
    setId(state, id) {      
      state.Id = id;
    },
  	setRole(state, role) {      
      state.role = role;
    },
    setTokenSize(state, size){
      state.tokenSize = size;
    },
    setActivePetIndex(state, index) {
      state.activePetIndex = index;
    },
    incrementTimer(state) {
      state.timer++;
      localStorage.setItem("timer", state.timer.toString()); // 타이머 값을 로컬 Save소에 Save합니다.
    },
    resetTimer(state) {
      state.timer = 0;
      localStorage.removeItem("timer"); // 로컬 Save소에 Save된 타이머 값을 제거합니다.
      localStorage.removeItem("calorie"); // 로컬 Save소에 Save된 타이머 값을 제거합니다.
      localStorage.removeItem("distance"); // 로컬 Save소에 Save된 타이머 값을 제거합니다.
    },
    setTimer(state, value) {
      state.timer = value;
    },
  },
  actions: {
    startTimer({ commit, state }) {
      clearInterval(interval);
      interval = setInterval(() => {
        commit("incrementTimer");
      }, 1000);
    },
    stopTimer({ commit }) {
      clearInterval(interval); // interval 변수를 사용하여 인터벌을 정지시킵니다.
      interval = 0;
      commit("resetTimer");
    },
    async logOut({ commit }) {
      // let axios = require('axios');
      // let loginedid = localStorage.getItem('logined_id');
      // await axios.get(`${process.env.VUE_APP_BASE_ADDR}/api/users/logout/${loginedid}`).then((res:any=null) =>{
        localStorage.removeItem('token');
        localStorage.removeItem('Id');
        localStorage.removeItem('role');
        localStorage.removeItem('logined_id');
        document.cookie = `accessToken=;`;
        document.cookie = `token_type=;`;
        document.cookie = `expire_date=;`;
        document.cookie = `user_id=;`;
        document.cookie = `role=;`;
        document.cookie = `user_type_name=;`;
        document.cookie = `user_type_id=;`;
        document.cookie = `keepLogin=;`;
        commit('setTokenSize', 0);
        commit('setId', '');
        commit('setRole', '');
//        window.location.reload();
      // });
    },
  },
  modules: {
  }
})
